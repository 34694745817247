import { inject, Injectable } from '@angular/core';
import { BaseService } from '@core';
import { Translation, TranslationInput, TranslationLanguage } from '@gqlSchema';
import { TranslateService } from '@ngx-translate/core';
import { DocumentNode } from 'graphql';
import { map, Observable, tap, Unsubscribable } from 'rxjs';
import {
  mutationCreateUpdateTranslation,
  SelectAllFields,
  selectByKey,
  selectByLanguageQuery,
  SelectDeleteRestoreFields,
  SelectOneFields,
} from '../graphql/translation.fragments';

@Injectable({
  providedIn: 'root',
})
export class TranslationService extends BaseService<TranslationLanguage> {
  tt = inject(TranslateService);

  protected override selectOneFields: DocumentNode = SelectOneFields;
  protected override selectAllFields: DocumentNode = SelectAllFields;
  protected override deleteRestoreFields: DocumentNode =
    SelectDeleteRestoreFields;

  loadedTranslations = false;
  currentLanguage!: string;
  translations$!: Unsubscribable;

  constructor() {
    super();
    this.initGQL({ names: { oneName: 'translation' } });
  }

  public getTranslationsByKey(key: string): Observable<Translation[]> {
    return this.query({
      query: selectByKey,
      slot: 'translatonsByKey' + key,
      data: { key },
      useCache: true,
    }).pipe(
      map((e: any) => e?.data?.translationsByKey),
    ) as unknown as Observable<Translation[]>;
  }

  public createUpdateTranslation(
    item: TranslationInput[],
  ): Observable<TranslationLanguage> {
    return this.mutate({
      mutation: mutationCreateUpdateTranslation,
      variables: { item },
    }).pipe(
      tap((e) => {
        this.updateCache(e as any);
      }),
    );
  }

  public updateCache(trans: Translation[]) {
    let query: any = this.apollo.client.cache.readQuery({
      query: this.selectAllQuery,
      variables: {
        pageRequest: this.pageRequest,
        filterRequest: this.filterRequest,
      },
    });

    if (!query) return;

    let wq: {
      translations: {
        data: TranslationLanguage[];
        totalCount: number;
      };
    } = JSON.parse(JSON.stringify(query));

    let i = wq.translations.data.findIndex(
      (e: TranslationLanguage) => e.key === trans[0].key,
    );

    if (i >= 0 && wq.translations.data[i].translations)
      wq.translations.data[i].translations = [...trans];

    this.apollo.client.cache.writeQuery({
      query: this.selectAllQuery,
      variables: {
        pageRequest: this.pageRequest,
        filterRequest: this.filterRequest,
      },
      data: wq,
    });
  }

  public refetchTranslationsByLanguage(isoCode2: string) {
    this.langRef.refetch({ isoCode2 });
  }

  langRef: any;
  public getTranslations(langCode: string = 'EN') {
    var _trans;
    try {
      _trans = JSON.parse(
        localStorage.getItem(`__translations_${langCode}`) as string,
      );
    } catch (error) {
      localStorage.removeItem(`__translations_${langCode}`);
    }
    if (_trans) {
      this.fillUpTranslations(langCode, _trans);
    }
    if (!!this.translations$) {
      // this.translations$.unsubscribe();
    }
    this.langRef = this.apollo.watchQuery({
      query: selectByLanguageQuery,
      variables: { isoCode2: langCode },
    });

    this.translations$ = this.langRef.valueChanges
      .pipe(
        map((result: any) => {
          if (!result.data || !result.data.translationsByLanguage) {
            return;
          }

          const translations: any = {};
          result.data.translationsByLanguage.map((tr: any) => {
            translations[tr.key] = tr.value;
          });

          localStorage.setItem(
            `__translations_${langCode}`,
            JSON.stringify(translations),
          );
          this.fillUpTranslations(langCode, translations);
        }),
      )
      .subscribe(() => {});
  }

  private fillUpTranslations(langCode: string, translations: any) {
    this.tt.setTranslation(langCode, translations);

    this.tt.setDefaultLang(langCode);
    this.tt.use(langCode);
    this.loadedTranslations = true;
    this.currentLanguage = langCode;
  }
}
