import { gql } from 'apollo-angular';

// #region Fragments
export const SelectOneFields = gql`
  fragment SelectOneFieldsTranslation on TranslationLanguage {
    id
    key
    value
    languageId
    language {
      id
      name
    }
    deleted
    created
    modified
  }
`;

export const SelectAllFields = gql`
  fragment SelectAllFieldsTranslation on TranslationLanguage {
    id
    key
    translations {
      languageId
      value
      language {
        id
        name
      }
    }
  }
`;

export const SelectDeleteRestoreFields = gql`
  fragment DeleteRestoreFieldsTranslationLanguage on TranslationLanguage {
    id
    deleted
  }
`;
//#endregion

// TODO DOING

export const selectByLanguageQuery = gql`
  query translationsByLanguage($isoCode2: String!) {
    translationsByLanguage(isoCode2: $isoCode2) {
      id
      key
      value
    }
  }
`;

export const selectByKey = gql`
  query translationsByKey($key: String!) {
    translationsByKey(key: $key) {
      id
      language {
        id
        name
      }
      languageId
      key
      value
    }
  }
`;

export const mutationCreateUpdateTranslation = gql`
  mutation createUpdateTranslations($item: [TranslationInput!]!) {
    createUpdateTranslations(item: $item) {
      id
      language {
        id
        name
      }
      languageId
      key
      value
    }
  }
`;
